<template>
    <div>
        <v-dialog v-model="reloadDialog" 
            max-width="900px"
            persistent
        >
            <v-card style="position: relative;">
                <v-card-title class="light--text"
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                >
                    <span class="white--text headline">Nova versão disponível</span>
                </v-card-title>
                <v-card-text class="py-4">
                    <p class="ma-0 text-body-1">
                        Uma nova versão está disponível. É altamente recomendado que você atualize o sistema.
                        <br />
                        Para isso, pressione os botões <b>{{ reloadCommand }}</b> ao mesmo tempo.
                    </p>
                    <p class="ma-0 mt-6 text-caption font-weight-bold error--text">
                        Ao ignorar o seu sistema estará sujeito a problemas de performance e execução.
                    </p>
                </v-card-text>
                <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                    <v-divider class="mx-4"></v-divider>
                    <v-card-actions class="px-6 py-6">
                        <v-spacer></v-spacer>
                        <v-btn text
                            color="primary"
                            @click="closeDialog()"
                        >
                            Ignorar
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    computed: {
		...mapState('auth', ['reloadDialog']),

        currentBrowser() {
            let userAgent = navigator.userAgent;
            let browserName;

            if (userAgent.match(/chrome|chromium|crios/i)){
                browserName = "chrome";
            } else if (userAgent.match(/firefox|fxios/i)){
                browserName = "firefox";
            } else if (userAgent.match(/safari/i)){
                browserName = "safari";
            } else if (userAgent.match(/opr\//i)){
                browserName = "opera";
            } else if (userAgent.match(/edg/i)){
                browserName = "edge";
            } else{
                browserName="No browser detection";
            }
            
            return browserName;
        },

        reloadCommand() {
            if (this.currentBrowser === 'safari') {
                return 'Command + Option + R'
            } else {
                return 'Shift + F5'
            }
        }
    },

    methods: {
		...mapMutations('auth', ['setReloadDialog']),

        closeDialog() {
            this.setReloadDialog(false)
        }
    }
}
</script>

<style>

</style>
