const getDefaultState = () => {
    return {
        planningParameters: null,
        planningFilters: []
    };
};

const state = getDefaultState();

const getters = {
    getPlanningParameters: (state) => state.planningParameters,
    getPlanningFilters: (state) => state.planningFilters,
};

const mutations = {
    setPlanningParameters (state, payload) {
        state.planningParameters = payload;
    },

    resetPlanningParameters (state) {
        state.planningParameters = null;
    },

    setPlanningFilters (state, payload) {
        let index = state.planningFilters.findIndex((p) => p.path === payload.path);
        if (index > -1) {
            state.planningFilters[index] = payload
        } else {
            state.planningFilters.push(payload)
        }
    },

    resetPlanningFilters (state, payload) {
        state.planningFilters = state.planningFilters.filter((p) => p.path !== payload)
    },

    restorePlanningFilters (state, payload) {
        state.planningFilters = payload
    }
};

export default {
    state,
    getters,
    mutations,
};
