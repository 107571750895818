const getDefaultState = () => {
    return {
        helpDialog: false,
        helpDialogTitle: '',
    }
}

const state = getDefaultState()

const getters = {
    getHelpDialog: state => state.helpDialog,
    getHelpDialogTitle: state => state.helpDialogTitle,
}

const mutations = {
    setHelpDialog(state, payload) {
        state.helpDialog = payload
    },

    setHelpDialogTitle(state, payload) {
        state.helpDialogTitle = payload
    },
}

export default {
    state,
    getters,
    mutations
}