import Vue from "vue";
import jwt_decode from "jwt-decode";
import configs from "@/configs";
import getModulePort from "@/helpers/getModulePort";
import planning from "./planning";

const getDefaultState = () => {
    return {
        permissions: [],
        hash: "",
        token: "",
        iat: "",
        exp: null,
        user: null,
        id: null,
        status: "",
        menu: null,
        clientModules: null,
        sessionExpiredDialog: false,
        enableSessionExpiredDialog: false,
        invalidHashDialog: false,
        urlPathName: null,
        reloadDialog: false,
    };
};

const state = getDefaultState();

const getters = {
    getPermissions: (state) => state.permissions,
    getToken: (state) => state.token,
    getTokenExp: (state) => state.exp,
    getHash: (state) => state.hash,
    getUser: (state) => state.user,
    getStatus: (state) => state.status,
    getMenu: (state) => state.menu,
    getClientModules: (state) => state.clientModules,
    getSessionExpiredDialog: (state) => state.sessionExpiredDialog,
    getEnableSessionExpiredDialog: (state) => state.enableSessionExpiredDialog,
    getInvalidHashDialog: (state) => state.invalidHashDialog,
    getUrlPathName: (state) => state.urlPathName,
};

const mutations = {
    setAuth (state, payload) {
        state.id = payload.id;
        state.token = payload.token;
        state.iat = jwt_decode(state.token).iat;
        state.exp = jwt_decode(state.token).exp;
        state.menu = payload.menu;
    },

    setToken (state, token) {
        state.token = token;
    },

    setSessionExpiredDialog (state, payload) {
        state.sessionExpiredDialog = payload;
    },

    setEnableSessionExpiredDialog (state, payload) {
        state.enableSessionExpiredDialog = payload;
    },

    setInvalidHashDialog (state, payload) {
        state.invalidHashDialog = payload;
    },

    setUrlPathName (state, payload) {
        state.urlPathName = payload;
    },

    setTokenIat (state, iat) {
        state.iat = iat;
    },

    setTokenExp (state, exp) {
        state.exp = exp;
    },

    setUser (state, user) {
        state.user = user;
    },

    setHash (state, hash) {
        state.hash = hash;
    },

    setMenu (state, payload) {
        state.menu = payload;
    },

    setClientModules (state, payload) {
        state.clientModules = payload;
    },

    setPermissions (state, payload) {
        state.permissions = payload;
    },

    resetState (state) {
        let filtersbkp = planning.getters.getPlanningFilters
        Object.assign(state, getDefaultState());
        planning.mutations.restorePlanningFilters(filtersbkp)
    },

    setReloadDialog (state, payload) {
        state.reloadDialog = payload;
    },
};

const actions = {
    async authenticate ({ state, commit, dispatch }, user) {
        try {
            state.status = "loading";

            const payload = {
                email: user.email,
                password: user.password,
            };

            const res = await Vue.prototype.$http.post(
                Vue.prototype.$ipUser + "user/login",
                payload
            );
            if (res) {
                commit("setHash", res.data.hash);

                await dispatch("setToken", res.data.token);
                state.id = res.data.id[0];
                await dispatch("setMenu");
                await dispatch("setPermissions");
                await dispatch("setClientModules");
                return "success";
            }
        } catch (err) {
            return err;
        }
    },

    async getHashAuth ({ state, commit, dispatch }, payload) {
        if (!payload) {
            payload = { token: state.token };
        }

        try {
            const res = await Vue.prototype.$http.post(
                Vue.prototype.$ipUser + "user/get-hash",
                { ...payload }
            );
            if (res.data.hash) {
                commit("setHash", res.data.hash);

                dispatch("setToken", res.data.token);
                state.id = res.data.id[0];
                dispatch("setMenu");
                dispatch("setPermissions");
                dispatch("setClientModules");
                return "success";
            } else {
                return "login";
            }
        } catch (err) {
            console.log("error: ", err);
            return err;
        }
    },

    async hashAuthenticate ({ state, commit, dispatch }, hash) {
        commit("setHash", hash);

        try {
            const res = await Vue.prototype.$http.post(
                Vue.prototype.$ipUser + "user/hash/login",
                { hash }
            );
            if (res) {
                dispatch("setToken", res.data.token);
                state.id = res.data.id[0];
                dispatch("setMenu");
                dispatch("setPermissions");
                dispatch("setClientModules");
                return "success";
            }
        } catch (err) {
            console.log("error: ", err);
            return err;
        }
    },

    async setToken ({ commit }, token) {
        commit("setToken", token);
        commit("setTokenIat", jwt_decode(token).iat);
        commit("setTokenExp", jwt_decode(token).exp);
        const userId = jwt_decode(token).userId;

        try {
            const res = await Vue.prototype.$http.post(
                `${Vue.prototype.$ipUser}user/list/${userId}`,
                {}
            );

            if (res) {
                const user = res.data.rows[0];
                commit("setUser", user);
            }
        } catch (err) {
            console.log(err);
            return err;
        }

        return token;
    },

    async setMenu ({ commit }) {
        let groupedMenu = [];

        try {
            const res = await Vue.prototype.$http.post(
                `${Vue.prototype.$ipUser}user/menu`,
                {}
            );
            if (res) {
                let menu = res.data;

                menu.forEach((element) => {
                    let menuFrontendRoutePath = element.frontend_route_path;
                    let transactionParent = element.id_transaction_parent;

                    let index = groupedMenu.findIndex(
                        (menu) => menu.groupCode === transactionParent
                    );

                    if (index === -1) {
                        if (element.transactionCode.indexOf("MENU_GROUP") !== -1) {
                            let groupName = element.label;

                            groupedMenu.push({
                                groupRoutePath: menuFrontendRoutePath,
                                groupName: groupName,
                                groupCode: transactionParent,
                                icon: element.icon,
                                expand: false,
                                menus: [],
                            });
                        }
                    } else {
                        if (element.transactionCode.indexOf("MENU_GROUP") === -1) {
                            groupedMenu[index].menus.push(element);
                        }
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }

        commit("setMenu", groupedMenu);
    },

    async setClientModules ({ commit }) {
        const res = await Vue.prototype.$http.post(
            Vue.prototype.$ipClient + "client-module/list/client",
            {}
        );
        if (res) {
            let clientModules = [];

            res.data.rows.forEach((element) => {
                let activeModule = false;

                if(element.access_denied) return

                clientModules.push({
                    name: element.description,
                    icon: element.icon,
                    active: activeModule,
                    subdomain: element.subdomain,
                });
            });

            commit("setClientModules", clientModules);
        }
    },

    async setPermissions ({ commit }) {
        const res = await Vue.prototype.$http.post(
            Vue.prototype.$ipUser + "user/transactions",
            {}
        );
        if (res) {
            let transactionsToRemove = [
                // 'CRUD_ACCOUNT_GROUP_VERSION',
                // 'CREATE_ACCOUNT_GROUP_VERSION',
                // 'UPDATE_ACCOUNT_GROUP_VERSION',
                // 'DELETE_ACCOUNT_GROUP_VERSION',
            ];

            if (transactionsToRemove.length)
                transactionsToRemove.forEach((trm) => {
                    let index = res.data.transactionCodes.findIndex((t) => t === trm);
                    res.data.transactionCodes.splice(index, 1);
                });

            commit("setPermissions", res.data.transactionCodes);
        }
    },

    async logout ({ state, commit, dispatch }) {
        try {
            if (state.token) {
                await Vue.prototype.$http.patch(
                    Vue.prototype.$ipUser + "user/update-hash",
                    { hash: null }
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            commit("resetState");
            dispatch("redirectToLogin");
        }
    },

    redirectToLogin () {
        let url = null;
        const localEnv = window.location.href.indexOf(":80") > -1;
        const system = configs.system.toLowerCase();

        if (!localEnv) {
            let moduleEnv = "";
            if (configs.env === "homol" || configs.env === "dev") {
                moduleEnv = `.${configs.env}`;
            }

            url = `https://${system}${moduleEnv}.znaptech.com/?logout=1`;
        } else {
            let port = getModulePort("portal");
            url = `http://${system}.znaptech:${port}/?logout=1`;
        }

        window.location.href = url;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
